"use client";
import { ReactNode } from "react";
import styles from "./button.module.css";
import { DotLoader } from "./dot-loader";
import { useFormStatus } from "react-dom";

type Props = {
  children: ReactNode;
  type?: "submit";
  fw?: boolean;
  loading?: boolean;
  [key: string]: any;
};

export default function Button({
  children,
  fw,
  type,
  loading,
  ...props
}: Props) {
  const { pending } = useFormStatus();

  return (
    <button
      className={`${styles.button} ${fw ? styles.fw : ""}`}
      disabled={pending || loading}
      type={type}
      {...props}
    >
      {pending || loading ? <DotLoader mode={"dark"} /> : children}
    </button>
  );
}
