"use client";

import { useState, useEffect } from "react";
import Button from "@/components/button";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { DotLoader } from "./dot-loader";

export default function GetStarted({ ...buttonProps }) {
  const [isClicked, setIsClicked] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    setIsClicked(false);
  }, [pathname]);

  const handleClick = () => {
    setIsClicked(true);
  };

  const arrowStyle = {
    transform: "translate(8px, 4px)",
  };

  return (
    <Link href="/medical-survey/welcome" onClick={handleClick}>
      <Button {...buttonProps}>
        {isClicked && <DotLoader />}
        <div style={{ display: isClicked ? "none" : "unset" }}>
          Get Started
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            width="24"
            height="24"
            style={arrowStyle}
          >
            <path d="M67.34,15.9a3.55,3.55,0,1,0-5,5L87.85,46.44H3.53a3.56,3.56,0,0,0,0,7.11H87.85L62.3,79.06a3.62,3.62,0,0,0,0,5,3.53,3.53,0,0,0,5,0l31.6-31.6a3.47,3.47,0,0,0,0-5Z" />
          </svg>
        </div>
      </Button>
    </Link>
  );
}
