"use client";

import React, { useState } from "react";
import styles from "./toggle.module.css"; // Import your CSS module

type ToggleProps = {
  summary: React.ReactNode;
  children: React.ReactNode;
};

const Toggle = ({ summary, children }: ToggleProps) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className={styles.question}>
      <div className={styles.summary} onClick={() => setOpen(!isOpen)}>
        <h3>{summary}</h3>
        <div className={`${styles.icon} ${isOpen ? styles.iconOpen : ""}`} />
      </div>
      <div className={styles.details}>
        <div className={isOpen ? styles.contentOpen : styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Toggle;
