import React from "react";
import styles from "./dotloader.module.css";

type Props = {
  mode?: "light" | "dark";
};

export const DotLoader = ({ mode = "dark" }: Props) => {
  const dotClass = mode === "light" ? styles.dotLight : styles.dotDark;

  return (
    <div className={styles.loadingDots}>
      <div className={`${styles.dot} ${dotClass}`} />
      <div className={`${styles.dot} ${dotClass}`} />
      <div className={`${styles.dot} ${dotClass}`} />
    </div>
  );
};
